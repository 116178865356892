.slide:after{
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  background: #171635b5;
  top: 0;
  left: 0;
}
.comp-text{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99;
  color: white;
}
.comp-text h1{
  text-align: left;
  font-size: 100px;
}
.comp-text p{
  text-align: center;
  font-size: 50px;
}