.comp_row{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  overflow: hidden;
  padding: 20px;
}
.comp_row .comp_img{
  width: 400px;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 1px 1px 12px 0px #0d6efd26;
  transition: 1s;
  -webkit-transition: 1s;
  -moz-transition: 1s;
  -ms-transition: 1s;
  -o-transition: 1s;
}
.comp_row .comp_img img{
  width: 180px;
  transition: 1s;
  -webkit-transition: 1s;
  -moz-transition: 1s;
  -ms-transition: 1s;
  -o-transition: 1s;
}

.comp_row .comp_img:hover{
  transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
  transition: 1s;
  -webkit-transition: 1s;
  -moz-transition: 1s;
  -ms-transition: 1s;
  -o-transition: 1s;
}
.comp_row .comp_img:hover img{
  transform: scale(1.3) rotate(4deg);
  -webkit-transform: scale(1.3) rotate(4deg);
  -moz-transform: scale(1.3) rotate(4deg);
  -ms-transform: scale(1.3) rotate(4deg);
  -o-transform: scale(1.3) rotate(4deg);
  transition: 1s;
  -webkit-transition: 1s;
  -moz-transition: 1s;
  -ms-transition: 1s;
  -o-transition: 1s;
}

.comp_row .right{  transform: translateX(400%); -webkit-transform: translateX(400%); -moz-transform: translateX(400%); -ms-transform: translateX(400%); -o-transform: translateX(400%);
}
.comp_row .left{  transform: translateX(-400%); -webkit-transform: translateX(-400%); -moz-transform: translateX(-400%); -ms-transform: translateX(-400%); -o-transform: translateX(-400%);
}

.show{
  transform: translateX(0) !important;
  -webkit-transform: translateX(0) !important;
  -moz-transform: translateX(0) !important;
  -ms-transform: translateX(0) !important;
  -o-transform: translateX(0) !important;
}