.section{
  position: relative;
}
.section .img img{
  width: 100%;
  height: 880px;
}
.img::after{
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background: #171635b5;
  top: 0;
  left: 0;
}
.section_text{
  height: 400px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50% , -50%);
  -webkit-transform: translate(-50% , -50%);
  -moz-transform: translate(-50% , -50%);
  -ms-transform: translate(-50% , -50%);
  -o-transform: translate(-50% , -50%);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.section_text h1{
  font-size: 75px;
  color: white;
}
.section_category{
  position: absolute;
  top: 20px;
  right: 8px;
  font-size: 30px;
  color: white;
}
.section_logo img{
  position: absolute;
  top: 8px;
  left: 8px;
  width: 200px;
}