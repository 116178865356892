
.info{
  width: 100%;
  height: 500px;
}
.info ul {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.info ul li{
color: #dc3545;
font-size: 18px;
}
.info ul li:nth-child(even) {
color: #0d6efd;
}